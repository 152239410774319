// @flow

import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { StaticImage } from 'gatsby-plugin-image'
import { Link, useIntl } from 'gatsby-plugin-intl'
import { FormattedMessageSanitized } from '../FormattedMessageSanitized'
import React from 'react'
import ArticleMeta from '../../components/NewsPage/ArticleMeta'
import { Container } from '../../components/basic/Grid'
import ContactTeaser from '../ContactTeaser'
import SubpageHeroTeaser from '../SubpageHeroTeaser'
import Button from '../basic/Button'
import SocialMedia from './SocialMedia'

import messages from './PressRelease.lang'
import baseStyles from './PressRelease.scss'

const HeroTeaserImage = (
  <StaticImage
    src="../../images/heroteaser/news_heroteaser_bg_desktop.jpg"
    alt=""
    objectFit="cover"
    className="image"
  />
)

type Props = {
  ...StyleProps,
  article: Object,
  articleLinks?: Object,
  addon?: any,
  pageImgNoteLinks?: Object
}

const PressRelease = ({ styles, article, articleLinks, pageImgNoteLinks, addon }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <div className={styles.root}>
      <SubpageHeroTeaser
        title={formatMessage(messages.pageTitle)}
        titleTag="p"
        image={HeroTeaserImage}
        titleLink={'/newsroom/'}
        titleLinkLabel={formatMessage(messages.toOverview)}
        titleLinkButton
        imgAlt={article.imgAlt || "News about our projects"}
        pageImg={locale === 'de' && article.imgDE ? article.imgDE : article.img}
        pageImgNote={article.imgNote ?
          <FormattedMessageSanitized {...article.imgNote} values={pageImgNoteLinks} /> :
          undefined
        }
        pageImgDescription={article.imgSubtitle && formatMessage(article.imgSubtitle)}
        styles={styles.heroTeaser}
      />
      <Container fluid>
        <div className={styles.article}>
          <h1 className={styles.title}>{formatMessage(article.title)}</h1>
          <ArticleMeta date={article.date} publisher={article.publisher} styles={styles.meta} />
          <div className={styles.text}>
            {article.accent && (
              <strong>
                {formatMessage(article.accent)}
                <br />
                <br />
              </strong>
            )}
            {article.embeddedVideoIframeLink && (
              <div className={styles.videoWrapper}>
                <iframe
                  className={styles.videoIframe}
                  src={article.embeddedVideoIframeLink}
                  frameBorder="0"
                  allowFullScreen
                  // eslint-disable-next-line max-len
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                />
              </div>
            )}
            <FormattedMessageSanitized {...article.body} values={articleLinks} />
          </div>
          {addon}
          <Link to={'/newsroom/'} className={styles.btnLink}>
            <Button styles={styles.btn} tag="span" color="accent" iconRight="icon-arrow_03-right-medium">
              {formatMessage(messages.toOverview)}
            </Button>
          </Link>
        </div>
      </Container>
      <SocialMedia />
      <Container fluid>
        <ContactTeaser />
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(PressRelease)
